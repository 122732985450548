"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_health-shop-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Dict = _interopRequireDefault(require("./Dict"));
var install = function install(Vue) {
  Vue.mixin({
    data: function data() {
      if (this.$options.dicts instanceof Array) {
        var dict = {
          dict: {},
          label: {}
        };
        return {
          dict: dict
        };
      }
      return {};
    },
    created: function created() {
      var _this = this;
      if (this.$options.dicts instanceof Array) {
        new _Dict.default(this.dict).init(this.$options.dicts, function () {
          _this.$nextTick(function () {
            _this.$emit('dictReady');
          });
        });
      }
    }
  });
};
var _default = {
  install: install
};
exports.default = _default;