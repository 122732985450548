"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_health-shop-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.buildMenus = buildMenus;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.getMenusTree = getMenusTree;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMenusTree() {
  return (0, _request.default)({
    url: 'api/menus/tree',
    method: 'get'
  });
}
function buildMenus() {
  return (0, _request.default)({
    url: 'api/menus/build',
    method: 'get'
  });
}
function add(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'post',
    data: data
  });
}
function del(ids) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'delete',
    data: ids
  });
}
function edit(data) {
  return (0, _request.default)({
    url: 'api/menus',
    method: 'put',
    data: data
  });
}
var _default = {
  add: add,
  edit: edit,
  del: del,
  getMenusTree: getMenusTree
};
exports.default = _default;