"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/dev_health-shop-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCodeImg = getCodeImg;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
exports.sendMsg = sendMsg;
exports.sureData = sureData;
var _request = _interopRequireDefault(require("@/utils/request"));
var _rsaEncrypt = require("@/utils/rsaEncrypt");
function login(username, password, code, uuid) {
  return (0, _request.default)({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      code: code,
      uuid: uuid
    }
  });
}
function getInfo() {
  return (0, _request.default)({
    url: 'auth/info',
    method: 'get'
  });
}
function getCodeImg() {
  return (0, _request.default)({
    url: 'auth/code',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: 'auth/logout',
    method: 'delete'
  });
}
function sendMsg(phone) {
  return (0, _request.default)({
    url: '/auth/forgetPwd/sendMsg',
    method: 'post',
    data: {
      phone: phone
    }
  });
}
function sureData(userData) {
  var data = {
    phone: userData.phone,
    messageCode: userData.messageCode,
    newPass: (0, _rsaEncrypt.encrypt)(userData.newPass)
  };
  return (0, _request.default)({
    url: 'auth/forgetPwd',
    method: 'post',
    data: data
  });
}